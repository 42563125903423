<template>
  <div class="mainHeader-inner">
    <notification-settings-modal
      v-if="notificationSettingsModalVisible"
      :resourceId="$db.broker.id"
      @update:resourceId="notificationSettingsModalVisible = false"
      @save="notificationSettingsSaved"
      visible="notificationSet"
    />
    <nice-popover
      placement="bottom"
      width="400"
      trigger="click"
      :virtual-ref="$refs.notificationTrackingVisibleButton"
      virtual-triggering
      @before-enter="popoverOpened"
      @after-leave="trackingPopoverVisible = false"
    >
      <header class="popover-header">
        {{ $t("header.tracking") }}
        <a href="#" @click.prevent="trackingHelpVisible = true" class="popover-headerAction">
          <fa-icon name="question-circle" />
        </a>
      </header>
      <div class="popover-body">
        <trackings global v-if="trackingPopoverVisible" />
      </div>
    </nice-popover>
    <nice-popover
      placement="bottom"
      ref="notificationPopoverRef"
      width="450"
      trigger="click"
      :virtual-ref="$refs.notificationPopoverVisibleButton"
      virtual-triggering
      @before-enter="notificationPopoverOpened"
      @after-leave="notificationPopoverVisible = false"
    >
      <header class="popover-header">
        <a
          href="#"
          @click.prevent="notificationSettingsModalVisible = true"
          class="popover-headerAction popover-headerAction-left"
        >
          <tooltip :content="$t('admin.broker.notifications.notificationSettingsLabel')">
            <fa-icon name="cog" />
          </tooltip>
        </a>
        {{ $t("header.notification") }}
        <a href="#" @click.prevent="markAllNotificationsAsRead" class="popover-headerAction">
          <tooltip content="Alle als gelesen markieren">
            <fa-icon name="check" />
          </tooltip>
        </a>
      </header>
      <div class="popover-body">
        <div v-if="notification.error" class="popover-empty">{{ $t("header.notifError") }}/</div>
        <ul
          v-else
          v-infinite-scroll="fetchNotifications"
          :infinite-scroll-disabled="notification.loading || notification.noMore"
          class="tracking-list"
        >
          <li
            v-for="item in notification.data"
            :key="item.id"
            class="notification-item tracking-item tracking-item--clickable"
            :class="{ 'notification-item--unread': !item.read_at }"
            @click="openNotificationTarget(item)"
          >
            <div class="tracking-itemAvatar">
              <img v-if="!item.actor" src="/assets/images/logo-icon.png" />
              <avatar v-if="item.actor" :broker="item.actor" />
            </div>
            <div class="tracking-itemContent">
              <p class="tracking-itemHeader">
                <span class="tracking-itemTitle semi-bold">{{ item.title }}</span>
              </p>
              <p class="tracking-itemBody text-sm">{{ item.body }}</p>
              <p class="flex justify-between text-sm">
                <span :title="$customFilters.datetime(item.created_at)">
                  {{ $customFilters.emailCalendar(item.created_at) }}
                </span>
                <span>
                  <a
                    v-if="item.read_at"
                    href="#"
                    class="tracking-delete px-1 text-gray-600"
                    @click.stop="unreadNotification(item)"
                  >
                    {{ $t("header.markAsUnread") }}
                  </a>
                  <a
                    href="#"
                    class="tracking-delete px-1 text-red-600"
                    :title="$t('header.deleteNotif')"
                    @click.stop="deleteNotification(item)"
                  >
                    <fa-icon name="trash-alt" />
                  </a>
                </span>
              </p>
            </div>
          </li>
        </ul>
        <div v-if="notification.loading" class="popover-empty">
          <span class="spinner"></span>
        </div>
        <p class="text-center light py-3" v-else-if="notification.data.length <= 0">{{ $t("header.notifEmpty") }}</p>
        <p class="text-center light py-3" v-else-if="notification.noMore">{{ $t("header.notifNoMore") }}</p>
      </div>
    </nice-popover>

    <search-form v-if="$db.planActive('pro')" />

    <ul class="header-navigation">
      <header-tab href="/" :active="!exportArea && !adminArea" v-if="$db.planActive('pro', 'reshare')">
        {{ $t("header.crm") }}
      </header-tab>
      <header-tab
        v-if="$db.broker.canExportToPortals && $db.planActive('pro', 'reshare')"
        href="/export/cart"
        :active="exportArea"
      >
        {{ $t("header.export") }}
      </header-tab>
      <header-tab v-if="$db.planActive('pro', 'reshare')" href="/admin/brokers" :active="adminArea">
        {{ $t("header.adminArea") }}
      </header-tab>
      <img
        v-if="$db.planActive('portal_catapult')"
        src="@/assets/images/propstack-invers.svg"
        style="max-height: 20px"
      />
    </ul>

    <ul class="header-items" v-if="$db.planActive('pro', 'reshare')">
      <li v-if="upcomingEvent">
        <a
          href="#"
          class="text-white text-sm"
          :title="upcomingEvent.title"
          @click.prevent="openQuicktask('event', upcomingEvent.id)"
        >
          <fa-icon name="calendar-alt" class="mr-1 text-lg" />
          {{ $customFilters.time(upcomingEvent.startsAt) }}: {{ $customFilters.truncate(upcomingEvent.title, 40) }}
        </a>
      </li>
      <li v-if="$db.planActive('pro')" :title="$t('header.notification')">
        <a
          ref="notificationPopoverVisibleButton"
          href="#"
          class="header-item"
          @click.prevent
          :class="{ active: notificationPopoverVisible }"
        >
          <fa-icon name="bell" />
          <span v-if="notificationUnreadCounter > 0" class="header-itemIndicator">
            {{ notificationUnreadCounter > 9 ? "9+" : notificationUnreadCounter }}
          </span>
        </a>
      </li>
      <li :title="$t('header.tracking')">
        <a
          ref="notificationTrackingVisibleButton"
          href="#"
          class="header-item"
          @click.prevent
          :class="{ active: trackingPopoverVisible }"
        >
          <fa-icon name="bolt" />
          <span v-if="trackingUnreadCounter > 0" class="header-itemIndicator">
            {{ trackingUnreadCounter > 9 ? "9+" : trackingUnreadCounter }}
          </span>
        </a>
      </li>
    </ul>

    <dropdown class="btn-group header-items">
      <template v-slot:reference>
        <button class="profile-dropdown-toggle flex items-center">
          <broker-availability
            :broker="{ availability: $db.broker.availability }"
            class="mr-3 text-white"
            @click="openStatusModal"
            v-if="$db.planActive('pro', 'reshare')"
          />
          <avatar :broker="$db.broker" force-photo />
        </button>
      </template>
      <dropdown-section>
        <header class="px-3 py-1">
          <p>{{ $db.broker.name }}</p>
          <p class="text-gray-600">{{ $db.broker.email }}</p>
        </header>
      </dropdown-section>
      <dropdown-section v-if="$db.planActive('pro', 'reshare')">
        <dropdown-item :title="$t('header.setStatus')" @click="openStatusModal" />
        <dropdown-item :title="$t('header.outOfOffice')" @click="openOutOfOfficeModal" />
        <dropdown-item
          v-if="canEditSelf"
          :link="'/admin/brokers/' + $db.broker.id"
          :title="$t('header.userSettings')"
          data-tagid="userSettings"
        />
        <dropdown-item
          v-else-if="!brokerConnected"
          :title="$t('header.connectInbox')"
          @click="connectInboxModalVisible = true"
        />
      </dropdown-section>
      <dropdown-section>
        <dropdown-item :title="$t('header.logout')" @click="$emit('logout')" class="text-red-600" />
      </dropdown-section>
      <dropdown-section>
        <dropdown-item
          link="https://www.facebook.com/propstackcrm"
          new-tab
          title="Facebook"
          class="text-sm text-gray-600"
        />
        <dropdown-item
          link="https://www.linkedin.com/company/18109460"
          new-tab
          title="LinkedIn"
          class="text-sm text-gray-600"
        />
        <dropdown-item
          link="https://www.xing.com/companies/propstack"
          new-tab
          title="Xing"
          class="text-sm text-gray-600"
        />
      </dropdown-section>
    </dropdown>
    <div class="header-items btn-group">
      <dropdown v-if="$db.planActive('pro', 'reshare')">
        <template v-slot:reference>
          <span class="header-add cursor-pointer">
            <fa-icon name="plus" class="mr-1" />
            {{ $t("header.new") }}
          </span>
        </template>
        <dropdown-section>
          <dropdown-item
            v-if="$db.broker.availableInboxes.length > 0 || isDevelopment"
            @click="quickview('mail')"
            data-tagid="newEmail"
          >
            <template v-slot:title>
              <span>{{ $t("header.newEmail") }}</span>
              <span class="pull-right text-monospace footnote">alt m</span>
            </template>
          </dropdown-item>
          <template v-if="$db.planActive('pro')">
            <dropdown-item @click="quickview('note')">
              <template v-slot:title>
                <span>{{ $t("header.newNote") }}</span>
                <span class="pull-right text-monospace footnote">alt n</span>
              </template>
            </dropdown-item>
            <dropdown-item @click="quickview('todo')" data-tagid="newTodo">
              <template v-slot:title>
                <span>{{ $t("header.newTodo") }}</span>
                <span class="pull-right text-monospace footnote">alt w</span>
              </template>
            </dropdown-item>
            <dropdown-item @click="quickview('event')">
              <template v-slot:title>
                <span>{{ $t("header.newEvent") }}</span>
                <span class="pull-right text-monospace footnote">alt t</span>
              </template>
            </dropdown-item>
            <dropdown-item :title="$t('header.newInquiry')" @click="quickview('inquiry')" />
            <dropdown-item :title="$t('header.newLetter')" @click="quickview('letter')" />
            <dropdown-item
              v-if="$db.shopData.decisionCategories && $db.shopData.decisionCategories.length"
              :title="$t('header.newDecision')"
              @click="quickview('decision')"
            />
            <dropdown-item
              v-if="$db.extensionEnabled('paperless')"
              :title="$t('header.newContract')"
              @click="quickview('contract')"
            />
          </template>
        </dropdown-section>

        <dropdown-section>
          <dropdown-item :title="$t('header.newClient')" link="/contacts/clients/new" />
          <dropdown-item :title="$t('header.newProperty')" link="/portfolio/properties/new" data-tagid="newProperty" />
          <dropdown-item v-if="$db.planActive('pro')" :title="$t('header.newProject')" link="/portfolio/projects/new" />
        </dropdown-section>
      </dropdown>
      <span class="header-add cursor-pointer" @click="$router.push('/portfolio/properties/new')" v-else>
        <fa-icon name="plus" class="mr-1" />
        {{ $t("header.newProperty") }}
      </span>
    </div>

    <nice-dialog :title="$t('header.trackings.title')" v-model="trackingHelpVisible">
      <article>
        <p>
          {{ $t("header.trackings.explanation1") }}
          <br />
          {{ $t("header.trackings.explanation2") }}
          <br />
        </p>
        <p>
          <strong>{{ $t("header.trackings.emails") }}</strong>
          <br />
          {{ $t("header.trackings.emailsExplanation") }}
        </p>
        <p>
          <strong>{{ $t("header.trackings.lp") }}</strong>
          <br />
          {{ $t("header.trackings.lpExplanation1") }}
          <br />
          {{ $t("header.trackings.lpExplanation2") }}
        </p>
        <ol class="list-styled">
          <li>{{ $t("header.trackings.list.1") }}</li>
          <li>{{ $t("header.trackings.list.2") }}</li>
          <li>{{ $t("header.trackings.list.3") }}</li>
          <li>{{ $t("header.trackings.list.4") }}</li>
          <li>{{ $t("header.trackings.list.5") }}</li>
          <li>{{ $t("header.trackings.list.6") }}</li>
        </ol>
      </article>
    </nice-dialog>

    <property-merge-modal v-model:visible="propertyMergeVisible" />
    <form-dialog
      :autofocus="false"
      @submit="submitAvailability"
      :visible="availability.modalVisible"
      @update:visible="availability.modalVisible = $event"
      :title="$t('header.setStatus')"
      width="400px"
    >
      <form-section>
        <form-row direction="column" :title="$t('header.setStatus')">
          <nice-select v-model="availability.value" :options="availabilities" />
        </form-row>
      </form-section>
    </form-dialog>
    <out-of-office-form v-model:modalVisible="outOfOffice.modalVisible" />
    <todo-activities-form />
    <connect-inbox-modal
      :resourceId="connectInboxModalVisible ? $db.broker.id : null"
      @update:resourceId="connectInboxModalVisible = false"
      @save="emailConnectSaved"
      return-url="/"
    />
  </div>
</template>

<script>
import HeaderTab from "@/components/header/Tab"
import SearchForm from "@/components/header/SearchForm"
import TodoActivitiesForm from "@/components/TodoActivitiesForm.vue"
import Trackings from "@/components/Trackings"
import PropertyMergeModal from "@/components/property/MergeModal"
import shortcuts from "@/utils/shortcuts"
import eventBus from "@/config/event-bus"
import quickviewProperties from "@/config/quickview-properties"
import BrokerAvailability from "@/components/BrokerAvailability.vue"
import OutOfOfficeForm from "@/components/header/OutOfOfficeForm.vue"
import ConnectInboxModal from "@/components/ConnectInboxModal.vue"
import { datadogRum } from "@datadog/browser-rum"
import NotificationSettingsModal from "@/views/admin/broker/NotificationSettingsModal.vue"
import useEnvironment from "@/plugins/use-environment"

const PAGE_PER = 20

const formatNotification = function (resource, t) {
  let title = resource.actor ? resource.actor.name : "Propstack"
  let body = resource.target ? _.truncate(resource.target.title, { length: 40 }) : null

  switch (resource.notify_type) {
    case "MESSAGE_CREATED":
      title = t("header.newEmail")
      break
    case "MESSAGE_ASSIGNED":
      title += t("header.logs.messageAssigned")
      break
    case "MESSAGE_ARCHIVED":
      title += t("header.logs.messageArchived")
      break
    case "NOTE_ASSIGNED":
      title += t("header.logs.noteAssigned")
      break
    case "NOTE_CHANGED":
      title = t("admin.broker.notifications.note.note_changed")
      break
    case "NOTE_SUBSCRIBED":
      title = t("admin.broker.notifications.note.note_subscribed_title")
      break
    case "CANCELATION_ASSIGNED":
      title += t("header.logs.cancelationAssigned")
      break
    case "REMINDER_ASSIGNED":
      title += t("header.logs.reminderAssigned")
      break
    case "REMINDER_COMPLETED":
      title = t("admin.broker.notifications.reminder.reminder_completed")
      body = resource.target.title
      break
    case "REMINDER_CHANGED":
      title = t("admin.broker.notifications.reminder.reminder_changed")
      body = resource.target.title
      break
    case "REMINDER_ARCHIVED":
      title += t("header.logs.taskArchived")
      break
    case "EVENT_ASSIGNED":
      title += t("header.logs.eventAssigned")
      break
    case "EVENT_CHANGED":
      title += t("header.logs.eventChanged")
      break
    case "SMS_DELIVERY_FAILED":
      title += t("header.logs.smsDeliveryFailed")
      break
    case "SMS_REMINDER_ERROR":
      title += t("header.logs.smsReminderError")
      break
    case "IS24_ERROR":
      title += t("header.logs.is24Error")
      break
    case "JOB_FAILED":
      title = t("header.logs.JOB_FAILED")
      body = resource.error_message
      break
    case "TODO_ARCHIVED":
      title += t("header.logs.todoAssigned")
      break
    case "TODO_ASSIGNED":
      title += t("header.logs.todoAssigned")
      break
    case "TODO_COMPLETED":
      title += t("header.logs.todoCompleted")
      break
    case "COMMENT_CREATED":
      var typeNom = {
        Message: t("header.logs.commentCreatedType.message"),
        NOTE: t("header.logs.commentCreatedType.note"),
        REMINDER: t("header.logs.commentCreatedType.reminder"),
        LETTER: t("header.logs.commentCreatedType.letter"),
        EVENT: t("header.logs.commentCreatedType.event"),
      }
      title += resource.second_target
        ? t("header.logs.commentCreatedAlt", {
            type: typeNom[resource.second_target.sub_type || resource.second_target_type] || "",
            title: resource.second_target.title,
          })
        : t("header.logs.commentCreatedAlt")
      break
    case "QUERY_SAVED_CREATED":
      title = t("header.logs.newQuerySaved")
      body = "ID " + resource.target.id + ": " + resource.target.client_name
      break
    case "CLIENT_PROPERTY_ASSIGNED":
      title += t("header.logs.clientPropertyAssigned")
      body =
        (resource.target.project_name ? resource.target.project_name + " " : "") +
        (resource.target.property_name ? resource.target.property_name + " " : "") +
        (resource.target.client_name ? resource.target.client_name : "")
      break
    case "CLIENT_PROPERTY_DEAL_STAGE_CHANGED":
      title = t("header.logs.clientPropertyDealStageChange")
      body =
        (resource.target.project_name ? resource.target.project_name + " " : "") +
        (resource.target.property_name ? resource.target.property_name + " " : "") +
        (resource.target.client_name ? resource.target.client_name : "")
      break
    case "PROPERTY_ASSIGNED":
      title += t("header.logs.propertyAssigned")
      body = "Objekt: " + resource.target.title
      break
    case "PROPERTY_STATUS_CHANGED":
      title = t("header.logs.propertyStatusChanged")
      body = "Objekt: " + resource.target.title + " – neuer Status: " + resource.target.status
      break
    case "CLIENT_ASSIGNED":
      title += t("header.logs.clientAssigned")
      body = "Kontakt: " + resource.target.title
      break
    case "INFORMATION_MARKETING_SUBSCRIBED":
      title = "Marketing News: " + resource.target?.title
      body = resource.target?.body
      break
    case "INFORMATION_PRODUCT_SUBSCRIBED":
      title = "Produkt Update: " + resource.target?.title
      body = resource.target?.body
      break
  }

  return {
    ...resource,
    title,
    body,
    created_at_formatted: moment(resource.created_at).format("LLL"),
  }
}

let cachedUpcomingEvent = undefined

export default {
  emits: ["logout"],
  props: ["initialTrackingUnreadCounter", "initialNotificationUnreadCounter"],
  setup() {
    const { isDevelopment } = useEnvironment()
    return { isDevelopment }
  },
  components: {
    HeaderTab,
    SearchForm,
    TodoActivitiesForm,
    Trackings,
    BrokerAvailability,
    PropertyMergeModal,
    OutOfOfficeForm,
    ConnectInboxModal,
    NotificationSettingsModal,
  },

  watch: {
    initialTrackingUnreadCounter() {
      this.trackingUnreadCounter = this.initialTrackingUnreadCounter
    },
    initialNotificationUnreadCounter() {
      this.notificationUnreadCounter = this.initialNotificationUnreadCounter
    },
  },

  data(props) {
    return {
      trackingUnreadCounter: props.initialTrackingUnreadCounter,
      notificationUnreadCounter: props.initialNotificationUnreadCounter,
      trackingHelpVisible: false,
      trackingPopoverVisible: false,
      notificationPopoverVisible: false,
      notificationSettingsModalVisible: false,
      notificationSet: {},
      notification: {
        page: 0,
        data: [],
        loading: false,
        error: false,
        noMore: false,
      },
      call: {
        visible: false,
        callingNumber: null,
        callingClient: {},
      },
      availability: {
        value: this.$db.broker.availability,
        modalVisible: false,
      },
      outOfOffice: {
        modalVisible: false,
      },
      propertyMergeVisible: false,
      connectInboxModalVisible: false,
      brokerConnected: this.$db.broker.connected,
      upcomingEvent: cachedUpcomingEvent,
    }
  },

  methods: {
    subscribe() {
      this.$pusher.off(`notification:${this.$db.broker.id}`)
      this.$pusher.off(`incoming-call:${this.$db.broker.id}`)
      this.$pusher.off(`broker:${this.$db.broker.id}:flash`)

      this.$pusher.on(`broker:${this.$db.broker.id}:flash`, data => App.flashy(data.message))
      this.$pusher.on(`broker:${this.$db.broker.id}:tracking:created`, data => {
        this.trackingUnreadCounter = data.unread_counter
      })
      this.$pusher.on(`broker:${this.$db.broker.id}:notification:created`, data => {
        this.notificationUnreadCounter = data.unread_counter
      })

      this.$pusher.on(`incoming-call:${this.$db.broker.id}`, data => {
        let caller = `<a router-link href="/contacts/clients/new?phone=${data.from}">${data.from}</a>`

        if (data.calling_client && data.calling_client.name) {
          caller = `<a router-link href="/contacts/clients/${data.calling_client.id}/conversation">${data.calling_client.name}</a>`
        }

        if (caller) {
          const noteBtn = `<button class="js-call-quicknote" data-client-id="${
            data.calling_client?.id || ""
          }" style="color: black; background-color: #eff0f1; padding: 0.25rem 0.5rem; border-radius: 3px; white-space: nowrap;">＋ Neue Notiz</button>`

          this.$notify({
            title: "Neuer Anruf",
            dangerouslyUseHTMLString: true,
            message: `<div style="display: flex; justify-content: space-between; align-items: center; width: 290px"><span>${caller} ruft an...</span> ${noteBtn}</div>`,
            duration: 10000,
          })
        }
      })

      this.$pusher.on(`notification:${this.$db.broker.id}`, ({ title, desc }) => {
        App.info(title, desc)
      })
    },

    quickview(type, source = {}) {
      eventBus.$emit("quick-view", {
        type,
        mode: "edit",
        params: {
          source: {
            clientIds: quickviewProperties.clientId ? [quickviewProperties.clientId] : [],
            propertyIds: quickviewProperties.propertyId ? [quickviewProperties.propertyId] : [],
            projectIds: quickviewProperties.projectId ? [quickviewProperties.projectId] : [],
            ...source,
          },
        },
      })
    },
    fetchNotifications() {
      this.notification.page++
      this.notification.loading = true
      this.$axios
        .get(`/api/v1/notifications?page=${this.notification.page}`)
        .then(({ data }) => {
          this.notification.data = this.notification.data.concat(
            data.data.map(data => formatNotification(data, this.$t))
          )
          this.notification.error = false
          this.notification.loading = false
          this.notification.noMore = PAGE_PER * this.notification.page >= data.meta.total_count
        })
        .catch(() => {
          this.notification.loading = false
          this.notification.error = true
        })
    },
    notificationSettingsSaved() {
      this.notificationSettingsModalVisible = false
    },

    notificationPopoverOpened() {
      this.notificationPopoverVisible = true
      this.notification.page = 0
      this.notification.data = []
      this.notification.noMore = false
      this.fetchNotifications()
    },

    openNotificationTarget(notification) {
      this.markNotificationAsRead(notification)
      this.notificationPopoverVisible = false

      this.$refs.notificationPopoverRef.hide()
      if (notification.target_type === "Message") {
        this.openQuicktask("message", notification.target_id)
      } else if (notification.target_type === "Comment") {
        this.openQuicktask(notification.second_target_type.toLowerCase(), notification.second_target_id)
      } else if (notification.target_type === "Task") {
        if (notification.target.task_type === "SMS") {
          this.openQuicktask("sms", notification.target_id)
        } else {
          this.openQuicktask("task", notification.target_id)
        }
      } else if (notification.target_type === "Todo") {
        this.openQuicktask("todo", notification.second_target_id)
      } else if (notification.target_type === "Property") {
        this.$router.push(`/portfolio/properties/${notification.target_id}`)
      } else if (notification.target_type === "SavedQuery") {
        this.$router.push(
          `/contacts/clients/${notification.target.client_ids[0]}/queries?query=${notification.target_id}`
        )
      } else if (notification.target_type === "ClientProperty") {
        this.$router.push(`/opportunities/${notification.target_id}`)
      } else if (notification.target_type === "SystemNotification") {
        globalThis.open(notification.target?.link, "_blank")
      }
    },

    markAllNotificationsAsRead() {
      this.notificationUnreadCounter = 0
      for (let item of Array.from(this.notification.data)) {
        item.read_at = moment().format()
      }
      this.$axios.post("/api/v1/notifications/read_all")
    },

    markNotificationAsRead(item) {
      if (!item.read_at) {
        this.notificationUnreadCounter--
        item.read_at = moment().format()
        this.$axios.post("/api/v1/notifications/read", { notification_id: [item.id] })
      }
    },

    popoverOpened() {
      this.trackingUnreadCounter = 0
      this.trackingPopoverVisible = true
    },

    unreadNotification(item) {
      this.$api.update("Notification", item.id, { readAt: null })
      item.read_at = null
      this.notificationUnreadCounter++
    },

    deleteNotification(item) {
      if (confirm(this.$t("header.deleteNotifConfirm"))) {
        this.$api.destroy("Notification", item.id)
        this.notification.data = this.notification.data.filter(n => n.id !== item.id)
      }
    },

    async fetchUpcomingEvent() {
      if (this.upcomingEvent || this.upcomingEvent === null) return

      const { broker } = await this.$graphql(`query upcomingEvent {
        broker { upcomingEvent { id title startsAt location } }
      }`)

      this.upcomingEvent = broker.upcomingEvent
      cachedUpcomingEvent = broker.upcomingEvent
    },
    openQuicktask(type, id) {
      eventBus.$emit("quick-view", { type, mode: "view", id })
    },
    openStatusModal() {
      this.availability.modalVisible = true
    },
    openOutOfOfficeModal() {
      this.outOfOffice.modalVisible = true
    },
    submitAvailability() {
      this.$db.updateBroker({ availability: this.availability.value })
      this.availability.modalVisible = false
      App.flashy(this.$t("header.statusSuccess"))
    },
    emailConnectSaved() {
      this.connectInboxModalVisible = false
      this.brokerConnected = true
    },

    quickviewNote(clientId) {
      eventBus.$emit("quick-view", {
        type: "note",
        mode: "edit",
        params: {
          source: {
            clientIds: clientId ? [clientId] : [],
          },
        },
      })
    },
  },

  computed: {
    exportArea() {
      return this.$route.path.startsWith("/export/cart")
    },
    adminArea() {
      return this.$route.path.startsWith("/admin/")
    },
    productTourId() {
      return this.$db.broker.admin ? 8986 : 102586
    },
    canEditSelf() {
      return !this.$db.broker.cannotEditOneself
    },
    availabilities() {
      return [
        { id: "Büro", name: this.$t("header.availabilities.office") },
        { id: "Home Office", name: this.$t("header.availabilities.homeOffice") },
        { id: "Im Urlaub", name: this.$t("header.availabilities.vacation") },
        { id: "Krank", name: this.$t("header.availabilities.sick") },
      ]
    },
  },

  mounted() {
    shortcuts.bind("alt+m", "all", () => this.quickview("mail"))
    shortcuts.bind("alt+b", "all", () => this.quickview("letter"))
    shortcuts.bind("alt+n", "all", () => this.quickview("note"))
    shortcuts.bind("alt+w", "all", () => this.quickview("todo"))
    shortcuts.bind("alt+t", "all", () => this.quickview("event"))

    this.subscribe()

    $(".js-open-property-merge").on("click", e => {
      e.preventDefault()
      this.propertyMergeVisible = true
    })

    this.fetchUpcomingEvent()

    const self = this
    $(document).on("click", 'a[href*="/"]', function (e) {
      const $this = $(this)
      const routerLink = $this.attr("router-link")
      if (routerLink === undefined) return
      const href = $this.attr("href")
      e.preventDefault()
      self.$router.push(href)
    })
    $(document).on("click", ".js-call-quicknote", function (e) {
      const clientId = this.dataset.clientId
      if (clientId) self.quickviewNote(Number(clientId))
    })
    const categoryId = this.$db.shopData.phoneNoteTypeId
    if (categoryId) {
      $(document).on("click", 'a[href*="tel:"]', function (e) {
        if (!this.dataset.clientId) return
        self.quickview("note", {
          clientIds: [Number(this.dataset.clientId)],
          propertyIds: this.dataset.propertyId ? [Number(this.dataset.propertyId)] : undefined,
          categoryId: Number(categoryId),
        })
      })
    }
    const outgoingCallUrl = this.$db.shopData.outgoingCallUrl
    if (outgoingCallUrl) {
      $(document).on("click", 'a[href*="tel:"]', function (e) {
        e.preventDefault()
        self.$api
          .mutation("callClientViaUrl", {
            phone: this.href.replace("tel:", ""),
          })
          .then(_ => App.flashy(self.$t("header.logs.clientCalled")))
          .catch(self.$axios.handleError)
      })
    }
  },
}
</script>

<style scoped>
.mainHeader-inner {
  display: block;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.mainHeader-inner .avatarItem {
  height: 34px;
  width: 34px;
  cursor: pointer;
  line-height: 34px;
  background-color: var(--primary);
  background-size: cover;
  background-position: center;
}
.profile-dropdown-toggle::after {
  font-family: "Font Awesome 5 Pro";
  font-size: 16px;
  content: "\f107";
  display: block;
  position: relative;
  left: 5px;
  top: 0px;
  font-weight: 400;
}

.profile-dropdown-toggle {
  background: transparent;
  border: none;
  padding-right: 14px;
  padding-left: 14px;
}

.header-add {
  display: block;
  height: 34px;
  line-height: 34px;
  border-radius: 34px;
  background: #15ca6e;
  background: linear-gradient(115deg, #0000 33%, rgba(255, 255, 255, 0.3) 50%, #0000 66%) #15ca6e;
  background-size: 300% 100%;
  padding: 0 20px 0 15px;
  color: white;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}

.header-add:hover {
  animation: shine 1s infinite;
}

.header-itemIndicator {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  background: red;
  color: white;
  position: absolute;
  top: -3px;
  right: -10px;
  padding: 1px 5px;
  border-radius: 20px;
  height: 15px;
  line-height: 1;
}

@keyframes shine {
  0% {
    background-position: right;
  }
}

.header-navigation {
  display: flex;
  flex: 1;
}

.header-items {
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
}
.header-items > li {
  margin-left: 12px;
}

.header-item {
  display: block;
  width: 34px;
  height: 34px;
  line-height: 36px;
  cursor: pointer;
  text-align: center;
  position: relative;
  border-radius: 100%;
  color: white;
  font-weight: 500;
  font-size: 16px;
}

.header-item.active {
  background-color: rgba(255, 255, 255, 0.2);
}
.header-item:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
</style>
